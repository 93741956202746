$marketing-site-hero-height: 100vh;


//front site hero ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.front-site-hero {

    a {
        color: scale-color($sixtyeight-color, $lightness: 20%);
    }

    //Big screen
    @media screen and (min-width: 41em) {
        background: url('../img/6ixty8-pics/6ixty8-large-screen-hero-01.jpg') top right no-repeat;
        height: $marketing-site-hero-height;
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: $marketing-site-hero-height - 5vh;
        //margin-bottom: 10px;

        h1 {
            margin-top: 110px;
            margin-left: 30px;
            font-size: 2rem;
            color: $sixtyeight-color;
            text-align: center;
        }

        h3 {
            margin-left: 30px;
            font-size: 1.5rem;
            color: $sixtyeight-color;
        }

        p.subheader {
            color: scale-color($sixtyeight-color, $lightness:10%);
            font-size: 1.2rem;
            margin-left: 30px;
            line-height: 120%;
        }

        .front-cta {
            margin-top: 20px;
            margin-left: 30px;
            font-size: 1.5rem;
            line-height: 110%;
            text-align: center;
        }

        .flex-container {
            flex-direction: column;
            justify-content: flex-end;
            align-content: center;
            align-items: center;
            padding-bottom: 60px;

            a {
                font-size: 1.6rem;
                font-weight: bolder;
            }
        }

        .item-box {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-content: center;


            .item-one,
            .item-two,
            .item-three {
                width: 130px;
                height: 130px;
                margin: 20px 20px 5px 20px;
                background-color: scale-color($sixtyeight-color, $lightness:-50%);
                border-radius: 50%;
                border: 5px solid scale-color($sixtyeight-color, $lightness:20%);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .item-one {
                background: url('../img/6ixty8-pics/prod/prod-card-case-healgen-chts-004.jpg') center center no-repeat;
                background-size: cover;
            }

            .item-two {
                background: url('../img/6ixty8-pics/prod/kid-faceshield.jpg') center center no-repeat;
                background-size: cover;
            }

            .item-three {
                background: url('../img/6ixty8-pics/prod/prod-card-case-s3-cube.jpg') center center no-repeat;
                background-size: cover;
            }

            p.prod > a {
                font-size: 1rem;
                font-weight: bolder;
            }

        }

    }

    //Small screen
    @media screen and (max-width: 40em) {
        background: url('../img/6ixty8-pics/6ixty8-small-screen-hero-01.jpg') top right no-repeat;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        justify-content: space-around;
        height: $marketing-site-hero-height - 5vh;
        //align-items: flex-start;
        //padding-top: 55px;

        h1 {
            //margin-top: 150px;
            margin: 90px 5px 0 5px;
            font-size: 1.25rem;
            color: $sixtyeight-color;
            text-align: center;
        }

        h3 {
            margin: 0 10px;
            font-size: 1rem;
            color: $sixtyeight-color;
            text-align: center;
        }

        p.subheader {
            color: scale-color($sixtyeight-color, $lightness:10%);
            font-size: .8rem;
            margin: 5px 10px 0px 10px;
            text-align: center;
        }

        .flex-container {
            flex-direction: column;
            justify-content: flex-end;
            align-content: center;
            align-items: center;
            //padding-bottom: 55px;
            /*margin-bottom: 55px;*/
            /*height: 50vh;*/


            a {
                font-size: .8rem;
                font-weight: bolder;
            }
        }

        .item-box {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-content: center;


            .item-one,
            .item-two,
            .item-three {
                width: 60px;
                height: 60px;
                margin: 10px 10px 5px 10px;
                background-color: scale-color($sixtyeight-color, $lightness:-50%);
                border-radius: 50%;
                border: 3px solid scale-color($sixtyeight-color, $lightness:20%);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .item-one {
                background: url('../img/6ixty8-pics/prod/prod-card-case-healgen-chts-004.jpg') center center no-repeat;
                background-size: cover;
            }

            .item-two {
                background: url('../img/6ixty8-pics/prod/kid-faceshield.jpg') center center no-repeat;
                background-size: cover;
            }

            .item-three {
                background: url('../img/6ixty8-pics/prod/prod-card-case-s3-cube.jpg') center center no-repeat;
                background-size: cover;
            }

            p.prod > a {
                font-size: .7rem;
                font-weight: bolder;
            }

        }

        .flexy {
            display: block;
            text-align: center;
            height: 60px;
            margin-bottom: 50px;

            p {
                font-size: .7rem;
            }

        }

    }

    //smaller screen
    @media screen and (max-width: 320px) {
        height: $marketing-site-hero-height - 7vh;

        h1 {
            font-size: 1.2rem;
        }

        h3 {
            font-size: .8rem;
            line-height: 115%;
        }

        p.subheader {
            margin-top: 10px;
            font-size: .75rem;
            line-height: 115%;
        }


    }

}

.marketing-site-hero {
    background: url('../img/6ixty8-pics/6ixty8-large-screen-hero-01.jpg') top right no-repeat;
    height: $marketing-site-hero-height;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: row; //column;
    justify-content: space-between;
    //align-items: center;

    a {
        color: scale-color($sixtyeight-color, $lightness: 20%);
    }

    p {
        color: desaturate($sixtyeight-color, 60);
    }

    @media screen and (max-width: 40em) {
        background: url('../img/6ixty8-pics/6ixty8-small-screen-hero-05.jpg') top right no-repeat;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        //align-items: flex-start;
        padding-top: 55px;

    }

    @media screen and (min-width: 41em) {
        margin-bottom: 10px;
    }

}

.marketing-site-hero-content {
    max-width: $global-width;
    //margin: 0 auto;

    h1 {
        margin-top: 60px;
        font-size: 2rem;
        color: $sixtyeight-color;
    }

    h3 {
        font-size: 20px;
        color: $sixtyeight-color;
    }

    @media screen and (min-width: 41em) {
        width: 60vw;
        margin-top: 70px;
        margin-left: 5%;

        h1 {
            text-align: center;
        }

        h3 {

            padding-right: 40px;
        }

        p.subheader {
            padding-right: 90px;
        }

    }

    @media screen and (max-width: 40em) {
        padding-left: 5%;
        padding-right: 5%;


        h1 {
            text-align: center;
            font-size: 1.5rem;
            margin-top: 21px;
            margin-bottom: 10px;
        }

        h3 {
            text-align: center;
            font-size: 1.12rem;
        }

        p.subheader {
            font-size: .9rem;
            line-height: 120%;
        }

        p {
            font-size: .81rem;
        }

        p:last-child {
            margin-bottom: 40px;
        }

        .flexy {
            display: block;
            text-align: center;
            height: 60px;
            margin-bottom: 50px;

            p {
                font-size: .7rem;
            }

        }

    }

    .subheader {
        //font-size: 1rem;
        color: lighten($sixtyeight-color, 5);
        margin-bottom: 20px;

        @media screen and (min-width: 41em) {
            font-size: 1.15rem;
        }

        @media screen and (max-width: 40em) {
            text-align: center;
        }

    }

}

.bottom-content {
    max-width: $global-width;
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 41em) {
        width: 50vw;
        position: absolute;
        left: 0;
        bottom: 15px;
    }



    .button.round {
        margin-top: 5px;
        border-radius: 5000px;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px;
    }

    a.scroller {
        font-size: 1.15rem;
        margin-top: 20px;
        margin-bottom: 20px;
    }

}


.non-hero-txt {
    color: desaturate($sixtyeight-color, 60);

    @media screen and (min-width: 41em) {
        h3 {
            padding-left: 80px;
        }

        p {
            padding-left: 140px;
        }

    }

    @media screen and (max-width: 40em) {

        p {
            text-align: center;
        }

    }
}



//Animation
.to-bott {
    position: relative;
    animation: move-down 2.5s ease-out 0s infinite forwards;
}

@keyframes move-down {
    0% {
        top: -5px;
        opacity: 0;
    }

    50% {
        top: 8px;
        opacity: 1;
    }

    100% {
        top: -5px;
        opacity: 0;
    }
}
