$sixtyeight-color: #343039;

.social-footer {
    padding: .5rem;
    background: $sixtyeight-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: .9rem;
        color: $white;
        margin-bottom: 0;
        display: inline-block;
    }

    .social-footer-icons {
        li:last-of-type {
            margin-right: 0;
        }

        .fa {
            font-size: 1.3rem;
            color: $white;

            &:hover {
                color: darken($dark-gray, 25%);
                transition: color 0.3s ease-in;
            }
        }
    }

    div.logofooter {
        width: 80px;
        //height: 16px;
        //background-color: $topbar-tagline-color;
        display: inline-block;
        margin: 0;
    }
}
