$news-card-label-background: $primary-color;
$news-card-label-background-hover: scale-color($news-card-label-background, $lightness: -15%);

.bott-space {
    margin-bottom: 10px;
}

.news-card {
    background-color: $white;
    font-weight: 400;
    margin-bottom: 1.6rem;
    border-radius: 0.125rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    //flex-flow: nowrap;
    justify-content: center;
    position: relative;

    @include breakpoint(large) {
        //height: 550px;
        overflow: auto;
    }

    @include breakpoint(small) {
        height: 100%;
        overflow: auto;
    }

    .news-card-tag {
        margin-bottom: 0.5rem;

        .label {
            border-bottom-right-radius: 0.5rem;
            padding: .25rem 1.5rem .25rem 1.5rem;
            background-color: $news-card-label-background;
            color: $white;

            a {
                background-color: inherit;
                color: inherit;
            }

            &:hover,
            &:focus {
                background-color: $news-card-label-background-hover;

                a {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }


    .prodImgbox {
        //width: 85%;
        margin: 5px auto;
        position: sticky;
        -webkit-box-shadow: 0px 4px 6px -3px #000000;
        box-shadow: 0px 4px 6px -3px #000000;
    }

    .card-section {
        background-color: inherit;

        @include breakpoint(large) {
            height: 240px;
            overflow: auto;
            padding-bottom: 85px;
        }

        .news-card-date {
            font-size: 1em;
            color: $dark-gray;
        }

        .news-card-article {
            background-color: inherit;

            @include breakpoint(medium down) {
                margin-bottom: 50px;
            }

            .news-card-title {
                font-size: 1.2rem;
                line-height: 1.3;
                font-weight: bold;

                a {
                    text-decoration: none;
                    color: $dark-gray;
                    transition: color 0.5s ease;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            p.news-card-description {
                color: $dark-gray;
                line-height: 130%;
                //margin: 0px;

            }
        }

        .news-card-author {
            display: flex;
            flex-direction: column;
            justify-content: center;
            //align-items: center;
            align-content: center;

            //overflow: hidden;
            //padding-bottom: 1.6rem;

            .news-card-author-image {
                display: inline-block;
                vertical-align: middle;

                img {
                    border-radius: 50%;
                    margin-right: 0.6em;
                }
            }

            .news-card-author-name {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
