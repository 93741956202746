@import 'hamburger-mixin';

//  My Custom Settings for 6ixty8.com
//  -----------------------------
//official site color
$sixtyeight-color: #343039; //#2c3840;
$reveal-colorized: crimson;
$cta-bg: #006274;
$dropdownmenu-arrow-color: scale-color($sixtyeight-color, $lightness: 90%);

//oc-topnav Styling +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//oc-topnav-bg
.oc-topnav-bg {
    background-color: scale-color($sixtyeight-color, $lightness: 60%);
}

//oc-topnav-txt
.oc-topnav-txt {
    font-size: 1rem;

    a {
        color: scale-color($sixtyeight-color, $lightness: 20%);
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
        border-color: scale-color($sixtyeight-color, $lightness: 20%) transparent transparent;
    }

    @include breakpoint(medium down) {
        font-size: .65rem;

        .dropdown.menu > li.is-dropdown-submenu-parent > a {
            padding-right: .7rem;
        }

        .dropdown.menu > li > a {
            padding: 0.7rem .3rem;
        }

        .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
            border: inset 3px;
            content: '';
            border-bottom-width: 0;
            border-top-style: solid;
            border-color: scale-color($sixtyeight-color, $lightness: 20%) transparent transparent;
            right: 6px;
            left: auto;
            margin-top: -3px;
        }
    }
}

//oc-logo-searchbox Styling +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


//6ixty8 topbar menu styling ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.topbar-fixed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    background: $sixtyeight-color;
    //padding: 10px 10px;


    //adjusting flex direction on small screen 
    @media print,
    screen and (max-width: 40em) {
        flex-direction: column;
        align-items: stretch;
        padding: 0px auto;
    }

    .topbar-responsive-logo {
        font-size: 2rem;

        @media print,
        screen and (max-width: 40em) {
            font-size: 1rem;
        }

    }

    a {
        color: scale-color($sixtyeight-color, $lightness: 90%);
    }

    .title-bar {
        justify-content: space-between;
        flex-direction: row-reverse;
        background: $sixtyeight-color;

        p.nospan {
            font-size: .8rem;
        }

    }

    p.top-cta {
        color: $topbar-tagline-color;
        display: inline-block;
        margin-right: 8px;
        margin-left: 18px;
        letter-spacing: .25rem;

    }

    p {
        margin-bottom: 0px;
    }

}

#my-menu-icon,
.my-menu-icon {
    @include hamburger($color: white, $anim: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.33s);

}


#my-menu {
    background: $sixtyeight-color;
    width: 50wv;

    .first-sub {
        background: scale-color($sixtyeight-color, $lightness: 80%);
        border-radius: 8px;

        a {
            color: scale-color($sixtyeight-color, $lightness: -90%);
            padding-left: 30px;

            &:hover {
                background: scale-color(grey, $lightness: 90%);
                border-radius: 8px;
            }

        }

    }

    //in small screen the container bg should be 
    @media print,
    screen and (max-width: 40em) {
        background: scale-color($sixtyeight-color, $lightness: 80%);
        width: 100%;

        a {
            color: scale-color($sixtyeight-color, $lightness: -90%);

            &:hover {
                background: scale-color(grey, $lightness: 90%);
            }

        }

        ul.submenu > li {
            padding-left: 15px;
        }

    }

}

//.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
//    border-color: scale-color($sixtyeight-color, $lightness: -90%);
//}




//soon-content-styling +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.soon-content {
    margin-top: 30vh;
    display: flex;
    flex-direction: column;
    //    justify-content: center; this is useful when flex-direction is row
    align-items: center;
    height: 60vh;

}


//withbg styling
.withbg {
    background-size: cover;
    background-position: top center;

    @include breakpoint(large) {
        background: url('../img/6ixty8-pics/6ixty8-large-screen-hero-blank.jpg') no-repeat;
    }

    @include breakpoint(medium down) {
        background: url('../img/6ixty8-pics/6ixty8-small-screen-hero-blank.jpg') no-repeat;
    }

}

//arrow down code using just border prop
.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f00;
}


//optional hide and seek class +++++++++++++++++++++++++++++++++++++++++++++++++++

//hide in medium and small screen
@include breakpoint(medium down) {

    .small-hide {
        display: none;
    }
}

//hide in large screen
@include breakpoint(large) {

    .big-hide {
        display: none;
    }
}


//call to action (cta) line styling
.cta {
    background-color: scale-color($cta-bg, $lightness: -20%);
    //rgba($cta-bg, .5);
    border-radius: 10px 10px 0px 0px;
    margin: 0 auto 10px auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: 0px -3px 6px -3px #000000;
    box-shadow: 0px -3px 6px -3px #000000;

    p {
        margin: 10px;
        line-height: 105%;
        text-align: center;
        font-weight: bolder;
        color: aliceblue;
    }

    .cell p > a {
        color: scale-color($sixtyeight-color, $lightness: 40%);

    }
}




.cta-footer {
    text-align: center;
    color: scale-color($sixtyeight-color, $lightness: 20%);
}



//Reveal panel customization
@include breakpoint(large) {
    .top-padder {
        padding-top: 110px;
    }

    .topbar-responsive .menu {
        flex-direction: row-reverse;

        li {
            margin-left: 15px;
        }
    }

    .cta-footer {
        font-size: 2.5rem;
    }

    .reveal {
        min-width: 70vw;
        height: 90vh;
        border-radius: 15px;
        margin-top: 1.5%;
    }

    h1 {
        font-size: 1.25rem;
        font-weight: bolder;
        color: $sixtyeight-color;
    }

    .lead {
        margin-top: 10px;
        color: $sixtyeight-color;
        font-size: 1rem;
        font-weight: bolder;
        line-height: 110%;
    }

    .cta {
        background-color: scale-color($cta-bg, $lightness: -20%);
        //rgba($cta-bg, .5);
        //border-radius: 10px;
        margin: 0 auto 10px auto;
        width: 100%;

        p {
            margin: 10px;
            line-height: 105%;
            text-align: center;
            font-weight: bolder;
            color: aliceblue;
        }


    }
}

@include breakpoint(medium down) {
    .top-padder {
        padding-top: 70px;
    }

    .reveal {
        width: 90vw;
        height: 95%;
        min-height: 95%;
        border-radius: 15px;
        margin-top: 5%;
        //top: 50%;
        left: 50%;
        //transform: translateY(-50%);
        transform: translateX(-50%);

        h1 {
            margin-top: 10px;
            color: $sixtyeight-color;
            font-weight: bolder;
            line-height: 110%;
        }

        .lead {
            margin-top: 10px;
            color: desaturate($sixtyeight-color, 60);
            font-weight: bolder;
            line-height: 110%;
            font-size: 1rem;
        }

        ul > li {
            color: $sixtyeight-color;
            line-height: 115%;
            font-size: .9rem;
        }

        .colorized {
            color: $reveal-colorized;

        }

        .cta {
            background-color: scale-color($cta-bg, $lightness: -20%);
            //border-radius: 10px;
            //margin-bottom: 10px;

            p {
                margin: 10px;
                line-height: 105%;
            }


        }
    }

}

p.main-cta {
    text-align: center;
    color: $sixtyeight-color;
    font-weight: bolder;
    font-size: 1.5rem;
}
