$sixtyeight-color: #2c3840;

.shadowy {
    background: $light-gray;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-top: 20px;
        color: scale-color($sixtyeight-color, $lightness: 10%);

        @include breakpoint(medium down) {
            font-size: 2rem;
            font-weight: bolder;
            padding: 0px 20px;
        }
    }
}

.marketing-site-content-section {
    display: flex;
    flex-wrap: wrap;
    background: $light-gray;
    padding: 20px 20px 0px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .button.round {
        border-radius: 5000px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .marketing-site-content-section-img {
        align-self: center;
        flex: 1 0 100%;

        img {
            width: 100%;
        }

        @media screen and (min-width: 41em) {
            flex: 1 0 30%;

        }

    }

    @media screen and (max-width: 40em) {
        .small-hide {
            display: none;
        }
    }




    .marketing-site-content-section-block {
        padding: 1rem;
        flex: 1 0 100%;
        align-self: stretch;
        //background: $light-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media screen and (min-width: 41em) {
            flex: 4 0 0;
            padding: 2rem;
        }
    }
}

.marketing-site-content-section-block-header {
    font-weight: bolder;
    color: $sixtyeight-color;

    @media screen and (max-width: 40em) {
        font-size: 24px;
    }
}

.marketing-site-content-section-block-subheader {
    font-weight: lighter;
    line-height: 115%;
    color: $sixtyeight-color;


    @media screen and (max-width: 40em) {
        font-size: 14px;
    }
}

@media screen and (max-width: 40em) {
    .marketing-site-content-section {
        hr {
            width: 60%;
            margin: 1.25rem auto;
            border: 2px solid #989191;
        }
    }
}

@media screen and (min-width: 41em) {
    .marketing-site-content-section {
        hr {
            width: 60%;
            margin: 1.25rem auto 0 auto;
            border: 2px solid #989191;
        }
    }
}
